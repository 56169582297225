function getRandomNumber(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

window.addEventListener('heyflow-screen-view', (event) => {
    
    const offersTextElement = document.querySelector('#text-block-rich-text-66bac272 p')
    const randomNumber = getRandomNumber(50,150)
    if(offersTextElement){
        offersTextElement.innerHTML= `I ditt område har <strong>${randomNumber}</strong> kunder fått <strong>kostnadsfria</strong> erbjudanden under de senaste 7 dagarna.`



    }
    // console.log('heyflow screen view:', event.detail);
    const inputAddressElement = document.querySelector('[data-variable="input-address"]');
    console.log("Input address element", inputAddressElement)
    if (inputAddressElement) {
        const autocomplete = new google.maps.places.Autocomplete(inputAddressElement, {
            types: ['address'],
            componentRestrictions: { country: 'se' }
        });

        autocomplete.addListener('place_changed', () => {
            const place = autocomplete.getPlace();
            if (!place.geometry) {
                console.error("No details available for input: '" + place.name + "'");
                return;
            }
            console.log('Selected place:', place.formatted_address);
            inputAddressElement.value=place.formatted_address
        });
    } else {
        console.error('Input element not found');
    }

});

window.addEventListener('heyflow-submit', (event) => {
    // console.log('heyflow submit:', event.detail);
});
